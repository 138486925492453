/* 
 *  Core Owl Carousel CSS File
 *  v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
/* display none until init */
.owl-carousel{
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
  overflow: hidden;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}
  
.owl-carousel .owl-item{
  float: left;
}

.owl-controls {
  @media (max-width: @screen-sm-max) {
    top: -120px;
  }
  top: -200px;
  width: 100%;
  text-align: center;
  display: inline-block;
}

.owl-controls a {
  color: #336699;
}

.owl-controls a:hover {
  color: #336699;
}

.owl-controls .bg-prev {
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 50px;
  z-index:10; 
  background:#fff; 
  border: 1px solid #336699;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.owl-controls .bg-prev:before{
  content:""; 
  display:block; 
  position:absolute; 
  z-index:-1; 
  top:3px; 
  left:3px; 
  right:3px; 
  bottom:3px; 
  border:2px solid #336699
}

.owl-controls .prev {
  left: 15px;
  z-index: 11;
  position: absolute; 
  width: 50px;
  height: 50px;
  line-height: 32px;
}

.owl-controls .prev.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.owl-controls .prev:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.owl-controls .bg-next {
  position: absolute;
  float: right;
  z-index: 1;
  width: 50px;
  height: 50px;
  position:relative; 
  z-index:10; 
  background:#fff; 
  border: 1px solid #336699;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.owl-controls .bg-next:before{
  content:""; 
  display:block; 
  position:absolute; 
  z-index:-1; 
  top:3px; 
  left:3px; 
  right:3px; 
  bottom:3px; 
  border:2px solid #336699
}

.owl-controls .next {
  right: 15px;
  z-index: 11;
  position: absolute; 
  width: 50px;
  height: 50px;
  line-height: 32px;
}

.owl-controls .next.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.owl-controls .next:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}


.owl-theme .owl-controls .owl-page{
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  *display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page a{
  display: block;
  width: 12px;
  height: 12px;
  margin: 0px 5px;
  filter: Alpha(Opacity=50);/*IE7 fix*/
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: transparent;
  border: 2px solid #888;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
  filter: Alpha(Opacity=100);/*IE7 fix*/
  opacity: 1;
  display: inline-block;
}
.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility:    hidden;
  -ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}

