#mainpage .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 10000;
  transition: all 0.2s ease-in-out;
  height: 80px;
  text-align: center;
  line-height: 40px;
}

#mainpage .headernotistop {
  background-color: lightgray;  
}

#mainpage .headeristop {
  background-color: transparent;  
}

#mainpage .headernotistop {
  background-color: rgb(245, 243, 243);  
  opacity: 0.97;
}

#mainpage .headeristop {
  background-color: transparent;  
}

#mainpage .navbar-brand {
	font-family: 'Open Sans', sans-serif;
	font-size: 25px;
	text-transform: uppercase;
	color: #fff!important;
	transition: all 0.5s;
}	 

#mainpage .navbar-brand-nottop {
	font-family: 'Open Sans', sans-serif;
	font-size: 25px;
	text-transform: uppercase;
	color: darkgray!important;
	transition: all 0.5s;
}

#mainpage .navbar-nav>.navli>a {
	font-size: 13px;
	font-weight: 300;
	text-transform: uppercase;
	text-shadow: none;
	line-height: 80px;
	padding: 0px;
	letter-spacing: 0.5px;
}
#mainpage .navbar-nav>.navli-color>a {
color: #fff!important;
}


#mainpage .navbar-nav>.navli-color-nottop>a {
	color: darkgray!important;
}

#mainpage .navbar-nav>.navli :hover {
	color:orange!important;
	
}

#mainpage .modal-overlay-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, .65);
}

#mainpage .modal-content-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: auto;
  text-align: center;
  padding: 4px;
  cursor: pointer;
}

#mainpage .modal-content-div:after {
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  margin-left: -.05em;
  content: '';
}

#mainpage .modal-dialog-div {
  position: relative;
  outline: 0;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  max-width: auto;
  cursor: default;
  border-radius: 4px;
}

#mainpage .modal{
  z-index: 910000;
}

#mainpage .carouselDiv{
  height: 85vh;
  
}


#mainpage #quote-carousel {
  padding: 0 10px 30px 10px;
  margin-top: 60px;
}

#mainpage #quote-carousel .carousel-control {
  background: none;
  color: #CACACA;
  font-size: 2.3em;
  text-shadow: none;
  margin-top: 30px;
}
#mainpage #quote-carousel .carousel-indicators {
  position: relative;
  right: 50%;
  top: auto;
  bottom: 0px;
  margin-top: 20px;
  margin-right: -19px;
}
#mainpage #quote-carousel .carousel-indicators li {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  opacity: 0.4;
  overflow: hidden;
  transition: all .4s ease-in;
  vertical-align: middle;
}
#mainpage #quote-carousel .carousel-indicators .active {
  width: 128px;
  height: 128px;
  opacity: 1;
  transition: all .2s;
}
#mainpage .item blockquote {
  border-left: none;
  margin: 0;
}
#mainpage .item blockquote p:before {
  content: "f10d";
  font-family: 'Fontawesome';
  float: left;
  margin-right: 10px;
}